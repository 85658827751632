.warning-popover {
  background-color: #ffefd2; }

.warning-popover-header {
  background-color: #ffe3ac; }

.w-item {
  background-color: #ffefd2; }

.w-item:hover {
  background-color: #ffe3ac;
  cursor: pointer; }

.loader,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em; }

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.3);
  border-right: 1.1em solid rgba(0, 0, 0, 0.3);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.3);
  border-left: 1.1em solid black;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear; }

@keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* sketchy to stop the overflow of the big unicode checkmark */
.neg-marg {
  width: 22.2188px;
  height: 24px; }

.scroll-group {
  max-height: 300px;
  overflow-y: auto; }
