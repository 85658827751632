.transparent {
  background-color: transparent !important; }

.pagination-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.rt-thead.-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none !important;
  position: sticky;
  top: 0;
  background-color: white; }

.ReactTable .rt-tbody {
  overflow: unset !important; }

.rt-resizable-header-content {
  overflow: visible !important; }
