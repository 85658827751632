.option-select,
.option-select:active,
.option-select:focus {
  outline: none !important;
  box-shadow: none !important; }

.option-select:hover {
  cursor: pointer; }

.nonapplicable-option-select {
  color: #d3d3d3; }

.nonapplicable-option {
  color: #d3d3d3; }

.applicable-option {
  color: black;
  font-style: normal; }

.add-option {
  font-weight: bold;
  font-style: normal;
  background-color: #d3d3d3;
  opacity: 0.2;
  text-align: center; }

.grow-1 {
  transform: scale(1);
  transition: transform 0.1s ease-in; }

.grow-2 {
  transform: scale(1);
  transition: transform 0.1s ease-in; }

.grow-3 {
  transform: scale(1);
  transition: transform 0.1s ease-in; }

.grow-1:hover {
  transform: scale(1.2); }

.grow-2:hover {
  transform: scale(1.3); }

.grow-3:hover {
  transform: scale(1.4); }
