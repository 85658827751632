select {
  padding-right: 18px;
  padding-left: 4px;
  background: url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==") no-repeat 99% 55% !important;
  box-shadow: none;
  -webkit-appearance: none; }

.general-header {
  color: black;
  background-color: white;
  height: 38px;
  min-height: 38px; }

.navigation-background {
  background-color: #337ab7 !important; }

.navigation-text {
  color: #337ab7 !important; }

.no-hover-background {
  background-color: transparent !important;
  color: initial !important; }

.no-background {
  background-color: transparent !important; }

.no-focus-outline:focus {
  outline: 0 !important;
  box-shadow: none !important; }

.admin-tool-font {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 13.3333px !important; }

.btn-colored {
  color: #fff;
  font-size: 12px;
  max-width: 150px;
  max-height: 30px;
  margin-top: 0;
  border: 1px solid transparent; }

.btn-green {
  background-color: #5eb646; }
  .btn-green:hover {
    background-color: #54943f;
    border: 1px solid transparent; }

.transparent-border {
  border: 1px solid transparent; }

.fixed-height-btn {
  font-size: 12px;
  height: 31px;
  min-height: 31px; }

.btn-grey {
  background-color: #6d7580; }
  .btn-grey:hover {
    background-color: #5c6165; }

.btn-blue {
  background-color: #428bca; }
  .btn-blue:hover {
    background-color: #28608f;
    border: 1px solid transparent; }

.btn-yellow {
  background-color: #f0ad4e; }
  .btn-yellow:hover {
    background-color: #eea236;
    border: 1px solid transparent; }

.btn-info {
  background-color: #5bc0de; }
  .btn-info:hover {
    background-color: #31b0d5;
    border: 1px solid transparent; }

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover,
.pagination > .disabled > button,
.pagination > .disabled > button:focus,
.pagination > .disabled > button:hover {
  color: #777 !important;
  background-color: #fff !important;
  border-color: #ddd !important;
  cursor: not-allowed !important; }

.page-item.disabled {
  opacity: 1; }
  .page-item.disabled .page-link {
    pointer-events: auto; }

#root {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%; }

html {
  height: 100%;
  width: 100%;
  display: flex; }

body {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 13.3333px; }

/* General Styling Overrides */
.modal-header {
  background-color: #337ab7 !important;
  color: white !important; }
  .modal-header h5 {
    font-size: 16px !important;
    font-weight: normal !important; }

.list-group-item {
  border-bottom: 0; }

.custom-btn-outline-success {
  box-shadow: none !important;
  color: #28a745 !important;
  background: transparent !important;
  border-color: transparent !important; }
  .custom-btn-outline-success:hover {
    color: #19692c !important; }

.custom-btn-outline-danger {
  box-shadow: none !important;
  color: #dc3545 !important;
  background: transparent !important;
  border-color: transparent !important; }
  .custom-btn-outline-danger:hover {
    color: #a71d2a !important; }
  .custom-btn-outline-danger.allow-focus:focus {
    box-shadow: 0 0 0 0.2rem #dc3545 !important; }

.close {
  opacity: 0.75; }
  .close:hover, .close:focus {
    opacity: 0.5; }

.cursor-pointer {
  cursor: pointer; }

.no-click {
  pointer-events: none; }

.modal-content {
  z-index: 100; }

.rt-table {
  height: 100%; }
