*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

::-webkit-input-placeholder {
  font-size: 12px !important; }

::-moz-placeholder {
  font-size: 12px !important; }

::-ms-input-placeholder {
  font-size: 12px !important;
  color: #999; }

.spinner {
  display: flex;
  min-height: 0;
  min-width: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.4); }

.spinner .loader,
.spinner .loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em; }

.spinner .loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }

.spinner-text {
  color: white;
  letter-spacing: 1px;
  font-size: 1.1rem; }
