.tile {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between; }

.tile-truncated {
  display: inline;
  flex-direction: column;
  justify-content: space-between; }

.merged,
merged:hover {
  background-color: #a9e5a9;
  display: inline; }

.normal {
  background-color: #fcedc9;
  display: inline; }

.empty-text {
  display: block;
  height: 21px;
  min-width: 150px; }

.show-more-btn {
  color: #f2711c !important;
  text-decoration: none !important; }
