.prepend-select {
  display: flex;
  align-items: left;
  margin-left: 7px !important;
  margin-right: 13px !important;
  color: #253a57;
  font-size: 1em;
  transition: font-size 0.1s ease-in; }

.group:hover > .prepend-select {
  font-size: 1.2em; }

.hidden-select {
  pointer-events: none;
  color: #d3d3d3; }

.select-available {
  cursor: pointer; }

.cursor-change {
  cursor: pointer; }

table {
  width: 100%; }

.disabled-trash {
  color: #d3d3d3;
  pointer-events: none; }

.table-body-row:hover {
  background-color: #f2f2f2; }

.table-header {
  height: 40px; }

.no-pointer {
  pointer-events: none; }

.green-check {
  color: green; }

.red-times {
  color: red; }
