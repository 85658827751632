.super-small {
  line-height: 1 !important;
  vertical-align: middle;
  align-self: center; }

.popover-header-color {
  background-color: #ffe4e4 !important; }

.popover-body-color,
.outer-popover-body-color:hover {
  background-color: rgba(255, 242, 242, 0.5); }

.outer-popover-body-color {
  background-color: rgba(255, 242, 242, 0.7); }

.popover-body-color:hover {
  background-color: rgba(255, 242, 242, 0.9);
  cursor: pointer; }

.option-select {
  border: none !important;
  font-weight: bold !important; }

.option-select:disabled {
  color: lightslategray !important;
  background-color: transparent !important; }
