.dismiss-all-container {
  position: absolute;
  right: 5px;
  width: 50%;
  height: 50px;
  float: right;
  cursor: pointer; }
  .dismiss-all-container:hover .btn-outline-danger {
    background-color: #dc3545;
    color: white; }
