.bottom-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  position: relative; }

.jump-buttons {
  left: 0px;
  width: 112px; }

.jump-button-left {
  padding-right: 2px; }

.jump-button-right {
  padding-left: 2px; }

.undo-buttons {
  margin-left: 20px;
  left: 112px;
  width: 112px; }

.validate-buttons {
  right: 0px; }

.disabled-text {
  color: #d3d3d3; }
