.overlap-icon {
  position: absolute;
  z-index: 2;
  left: 92%;
  top: 11px; }

.options-box {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
  float: left;
  background-color: white;
  border-top: 0.4px solid rgba(0, 0, 0, 0.2); }

.table-hover > tbody > tr:hover {
  background-color: #d2d2d2;
  color: black; }

.tbody-style {
  display: table; }

.data-padding {
  padding: 0 0.75rem; }

.no-focus:focus,
.no-focus:active {
  outline: none !important;
  box-shadow: none !important; }

.popoverModified {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2); }

.style-font {
  font-size: 1em !important;
  color: black !important; }
