.company-header {
  word-break: break-word;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #afd9ee;
  min-height: 50px; }

.company-header-text {
  color: #253a57; }

.company-header-badges {
  display: flex;
  flex-direction: column;
  align-items: center; }

.fill {
  width: 100%;
  height: 100%; }
