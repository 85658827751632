.badge-container {
  display: flex;
  align-self: center;
  justify-content: center; }

.tile-word-break {
  word-break: break-word; }

.excl-hidden {
  color: transparent !important;
  border-color: transparent !important; }

.perfect-padding {
  padding: 0px 5px 0px 5px; }

.new-banner {
  text-align: center;
  transform: rotate(-45deg);
  font-size: 10px;
  margin-left: -30px; }

.new-banner-holder {
  width: 16px;
  margin-right: 5px; }
