.batch-update-btn {
  cursor: pointer; }

.batch-popover {
  background-color: #f2faff; }

.batch-popover-header {
  background-color: #253a57;
  color: white; }

.batch-item {
  background-color: #f2faff; }

.batch-item:hover {
  background-color: #dff3ff;
  cursor: pointer; }

.apply-batch-btn {
  background-color: #f2faff; }

.apply-batch-btn:hover {
  background-color: #dff3ff;
  color: black; }

.tag-disabled {
  color: #a0a0a0;
  pointer-events: none; }
