.list-group {
  flex-grow: 1;
  background-color: white; }

.company-list-group-item:hover {
  background-color: #d4f1ff !important; }

.regular {
  background-color: #afd9ee !important; }

.pagination-height {
  min-height: 34px; }

.page-form-container {
  display: flex;
  justify-content: center;
  min-height: 30px; }

.page-form-width {
  width: 20%; }

.page-form-border {
  color: black;
  float: left;
  padding: 2px 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd; }

.page-form-active-link {
  color: white !important; }

.page-form-link {
  color: black; }

.page-form-item {
  /* width: 32px; */
  flex: 1; }

.page-form-first {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 51.91px; }

.page-form-last {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 51.91px; }

.fixed-width-input {
  width: 60px !important; }

.page-display-container {
  min-height: 30px; }

.search-bar-btn-holder {
  width: 26px;
  min-width: 26px; }
