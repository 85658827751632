.action-btn {
  font-size: 12px;
  min-width: 150px;
  max-width: 150px;
  max-height: 30px;
  margin-top: 0; }

.btn-outline-success {
  border-color: #5eb646; }

.btn-outline-success:hover {
  background-color: #5eb646; }
