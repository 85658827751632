.prepend-upload {
  display: flex;
  align-items: center;
  margin-right: 10px !important;
  color: #253a57;
  font-size: 1em;
  transition: font-size 0.1s ease-in; }

.nowrap {
  flex-wrap: nowrap !important; }

.upload-form,
.upload-input {
  width: 100%; }

.nowrap:hover > .prepend-upload {
  font-size: 1.2em; }
