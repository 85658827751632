.min-width {
  min-width: 50px;
  overflow: hidden; }

.fill {
  height: 100%;
  width: 100%; }

.disabled {
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  opacity: 1; }

/* sketchy to align with other table item btn sizes.  Using fontawesome, icons
   are all different sizes so hardcoding width and height is a quick fix */
.fixed-btn {
  width: 22.2188px;
  height: 24px;
  font-size: 0.7em; }
