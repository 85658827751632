hr {
  /* transform: rotate(45deg); */
  position: absolute;
  width: 40px;
  left: -5px;
  top: 2px; }

.index-box {
  /* display: flex;
  flex-wrap: wrap */
  height: 40px; }

.adjusted {
  margin-top: 15px; }
