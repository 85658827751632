.merge-banner {
  background-color: #253a57;
  color: white;
  position: relative; }

.fixed-corner {
  position: absolute;
  left: 0vw; }

.anchor-ingest {
  position: absolute;
  right: 0;
  bottom: 0; }

.company-list {
  display: flex;
  flex-direction: column;
  /* background-color: #253a57; */ }

.transition-btn {
  background-color: transparent;
  transition: background-color 0.1s ease-in; }

.transition-btn:hover {
  background-color: lightslategray; }

.transition-btn > .grow {
  font-size: 1.5em;
  text-decoration: none;
  transition: text-decoration 0.1s ease-in; }

.transition-btn:hover > .grow {
  text-decoration: underline; }

.hidden {
  color: #253a57 !important;
  border: none !important; }

.overflow-hidden {
  overflow: hidden; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-y-overlay {
  overflow-y: overlay; }

.hoverflay-y {
  overflow-y: hidden; }
  .hoverflay-y:hover {
    overflow-y: overlay; }

.text-black {
  color: black; }

.full-height {
  height: 100%; }

.fixed-height-header {
  min-height: 38px; }

.fixed-height-button {
  min-height: 29px; }

.absolute-center-border {
  z-index: 1;
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #dee2e6; }

.bottom-nav-height {
  height: 132px;
  min-height: 132px; }

.nav-seperator-gap {
  padding: 1px; }

.flex-grow-3 {
  flex-grow: 3; }

.flex-grow-9 {
  flex-grow: 9; }
