.top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 20px; }

.top-text {
  font-weight: bold;
  margin-right: 20px; }

.import-field {
  margin-right: 10px;
  width: 20%;
  max-width: 300px; }

.upload-button {
  padding-top: 8.5px; }

.auto-classify-button {
  margin-left: 30px; }

.infer-row {
  width: 100%;
  min-height: 36px; }

.infer-tooltip {
  background-color: #666666;
  color: #eeeeee;
  border-radius: 5px;
  cursor: pointer; }

.hover-color {
  background-color: transparent;
  transition: background-color 0.1s ease-in;
  position: relative; }

.hover-color:focus,
.hover-color:active {
  box-shadow: none;
  outline: none; }

.hover-color:hover {
  background-color: lightslategray; }

/* btn hover color */
.hover-color > .grow {
  font-size: 1.5em;
  text-decoration: none;
  transition: text-decoration 0.1s ease-in; }

.hover-color:hover > .grow {
  text-decoration: underline; }

.upload-import-container {
  flex: 1; }

.pos-marg-top {
  margin-top: 5px; }

.jump-btn-container {
  align-self: center; }

.slow-grow {
  transform: scale(1);
  transition: transform 0.1s ease-in, background-color 0.1s ease-in; }

.slow-grow:hover {
  transform: scale(1.05); }

.min-height-footer {
  min-height: 45px;
  height: 45px; }

.small-font {
  font-size: 0.8rem; }
