.conflict-header {
  background-clip: content-box;
  height: 38px;
  min-height: 38px; }

.conflict-header-background {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.staged {
  background-color: #e0eee0; }

.full-height {
  height: 100%; }

.z-10 {
  z-index: 10; }
